@import '../../../vendor/styles/variables.css';

.os {
  &__title {
    text-align: center;
    font-weight: 300;
    color: var(--color-secondary);
  }

  &__otp-text {
    font-weight: 400;
    font-size: 13px;
    color: var(--color-secondary);
  }

  &__resend-text {
    font-weight: 700;
    font-size: 13px;
  }

  &__text {
    text-align: center;
    font-weight: 400;
    font-size: 13px;
    color: var(--color-secondary);
    margin-top: -4px !important;
  }

  &__submit {
    margin-top: 38px;
    margin-bottom: 24px;
    &:disabled {
      background-color: #d9d9d9;
      border-color: #ffffff;
    }
  }

  &__icon {
    border-radius: 45px;
    background-color: #e7e7e7;
    padding: 4px 0 4px 7px;
  }

  &__box {
    width: 93.88%;
    border: 1px solid #0eb550;
    border-radius: 4px;
    background: #f1fff7;
    position: fixed;
    bottom: 13px;
    &__btn {
      margin-top: 14px;
      background: rgba(14, 181, 80, 0.05) !important;
      color: #0eb550 !important;
      font-size: 13px !important;
    }
    &__text {
      font-weight: 400;
      font-size: 13px;
      color: var(--color-secondary);
      line-height: 20px;
    }
  }
}
